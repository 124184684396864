import "./ScrollAlert.css";
import Up from "../../Assets/up.svg";
import { useEffect } from "react";

function ScrollAlert() {

	useEffect(() => {
		const animDiv = document.getElementById('sa-root')
		animDiv.addEventListener('webkitAnimationEnd', function(event) { animDiv.style.display = 'none'}, false);
	},[])

	return (
		<div id="sa-root">
			<img src={Up} alt="" className="img-up"/>
			<p>Scroll up to jump back to a previous question</p>
		</div>
	)
}

export default ScrollAlert;