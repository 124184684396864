import "./Header.css";
import AkerLogo from "../../Assets/akerLogo.svg";
import Cross from "../../Assets/close.svg";

function Header({ value, callback, hideCross, whiteBG }) {

	const handleClick = () => {
		callback(!value);
	}

	return (
		<div className={`header-root ${whiteBG ? 'white-bg' : ''}`}>
			<img src={AkerLogo} alt="" className="nav-logo"/>
			{ !hideCross && <img src={Cross} alt="" onClick={() => handleClick()} className="nav-cross"/>}
		</div>
	)
}

export default Header;