import Cross from "../../Assets/close.svg";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import axios from "axios";
import { useState, useRef, useEffect } from "react";
import "./ExitRecommPopup.css";

function ExitRecommPopup({ isOpen, setOpen, plantRecommList }) {
  const coreRef = useRef(null);
  useOutsideClickNotifier(coreRef, setOpen);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isFormVisible, setFormVisible] = useState(false);
  const apiPayload = {};
  const [showFormErrors, setFormErrors] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [hasSubmitBeenPressed, setPressed] = useState(false);
  const [isSubmitDisabled, toggleSubmitDisabled] = useState(false);

  const getPayload = () => {
    apiPayload.email = email;
    apiPayload.firstName = firstName;
    apiPayload.lastName = lastName;
    apiPayload.uid = localStorage.getItem("InteractionID");
    apiPayload.plantRecommList = plantRecommList;
  };

  useEffect(() => {
    if (isFormValid) {
      toggleSubmitDisabled(true);
      getPayload();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/email-recomm`,
        data: apiPayload,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toggleSubmitDisabled(false);
            setOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toggleSubmitDisabled(false);
        });
    }
  }, [isFormValid]);

  const checkFormValidity = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      !email.match(/^\S+@\S+\.\S+$/)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hasSubmitBeenPressed) {
      setPressed(true);
      setFormErrors(true);
    }
    checkFormValidity();
    if (!isFormValid) {
      return;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "initial");
  }, []);

  return (
    <>
      <div className="erp-overlay" />
      <div ref={coreRef} className="erp-popup-root">
        <p className="erp-title">
          Are you sure <br />
          you want to exit?
          <img
            onClick={() => setOpen(!isOpen)}
            className="erp-cross"
            src={Cross}
            alt=""
          />
        </p>
        {isFormVisible ? (
          <form className="frm-recomm" onSubmit={(e) => handleSubmit(e)}>
            <p className="erp-thank-text">
              The recommendations will be sent on the email id below
            </p>
            <p className="spp-label erp-mr-auto">First Name</p>
            <input
              type="text"
              placeholder="John"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="spp-inp"
            />
            {showFormErrors && firstName.length === 0 && (
              <span className="err-spp">First name cannot be empty</span>
            )}
            <p className="spp-label">Last Name</p>
            <input
              type="text"
              placeholder="Doe"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="spp-inp"
            />
            {showFormErrors && lastName.length === 0 && (
              <span className="err-spp">Last name cannot be empty</span>
            )}
            <p className="spp-label">Email</p>
            <input
              type="email"
              placeholder="john@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="spp-inp"
            />
            {showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && (
              <span className="err-spp">Invalid email id</span>
            )}
            <button
              type="submit"
              disabled={isSubmitDisabled}
              className="erp-frm-button mb-10"
            >
              Done
            </button>
          </form>
        ) : (
          <p className="erp-thank-text frm-recomm">
            We can always continue from where you left off, just share your
            email id and we will send the recommendations to your email.
            <button
              onClick={() => {
                setFormVisible(true);
              }}
              className="erp-frm-button nmb"
            >
              Sure
            </button>
            <br />
            <a className="erp-fm-link" href="https://akercarboncapture.com/">
              Exit now
            </a>
          </p>
        )}
      </div>
    </>
  );
}

export default ExitRecommPopup;
