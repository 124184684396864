import { useState, useEffect } from "react";

const useIntersection = (element) => {
	const [isVisible, setVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				setVisible(entry.isIntersecting);
				if(entry.isIntersecting)
					observer.unobserve(element.current);
			}, { threshold: 1 }
		);

		observer.observe(element.current);

		return () => element.current && observer.unobserve(element.current);
	},[]);

	return isVisible;
}

export default useIntersection;