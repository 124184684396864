import "./PlantRec.css";
import Download from "../../Assets/download.svg";
import Share from "../../Assets/share.svg";

function PlantRec({ isBookMeetingPopupOpen, bookMeetingCallback, offer }) {
  const handleBookMeetingClick = () => {
    bookMeetingCallback(!isBookMeetingPopupOpen);
  };

  return (
    <>
      <div className={`plantrec-root mat-10`}>
        <div className="pr-img-wrapper">
          <img src={offer.plantImage} alt="" className="pr-img" />
        </div>
        <div className="pr-cont-wrapper">
          <span className="plr-icons-wrapper">
            <a
              href={offer.brochure}
              download
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Download} alt="" className="plr-sm-icon" />
            </a>
            <img
              src={Share}
              alt=""
              style={{ visibility: "hidden" }}
              id="share"
              className="plr-sm-icon"
              onClick={null}
            />
          </span>
          <div>
            <p className="plr-head">
              {offer.plantName}
              <span className="tm-pl">TM</span>
            </p>
            <p className="plr-subhead">{offer.plantDesc}</p>
            <div
              className={`ccas-div ${
                offer.plantName !== "Just Catch" ? "ns-plrec" : ""
              } ${offer.availableAsCcaas ? "" : "disable-ccas"}`}
            >
              {offer.availableAsCcaas
                ? "Available as carbon capture as a service"
                : "Carbon capture as a service currently unavailable in your country"}
            </div>
          </div>
          <div>
            <div className="pr-features-wrapper">
              {offer.details.map((item, index) => {
                return (
                  <div className="feat-wrapper">
                    <span className="pr-feat">{item.key}</span>
                    <br />
                    <span className="pr-subf">{item.value}</span>
                  </div>
                );
              })}
            </div>
            <div className="plr-btn-list">
              <button
                className="plbtn2"
                onClick={() => handleBookMeetingClick()}
              >
                Discuss with one of our engineers
              </button>
              <a
                href={offer.plantDetailURL}
                target="_blank"
                rel="noopener noreferrer"
                className="plbtn1"
              >
                View Details
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mob-plr-btn-list">
        <button
          className="plbtn2 mob-btn2"
          onClick={() => handleBookMeetingClick()}
        >
          Discuss with one of our engineers
        </button>
        <a
          href={offer.plantDetailURL}
          target="_blank"
          rel="noopener noreferrer"
          className="plbtn1 mob-btn1"
        >
          View Details
        </a>
      </div>
    </>
  );
}

export default PlantRec;
