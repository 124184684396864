import CTAButton from "../../Components/CTAButton/CTAButton";
import Header from "../../Components/Header/Header";
import RDButton from "../../Components/RDButton/RDButton";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Delete from "../../Assets/delete.svg";
import AddPlant from "../../Assets/addplant.svg";
import { useMemo, useEffect, useRef, useState } from "react";
import "./HomePage.css";
import ExitPopup from "../../Components/ExitPopup/ExitPopup";
import ScrollAlert from "../../Components/ScrollAlert/ScrollAlert";
import FlueSpecs from "../../Components/FlueSpecs/FlueSpecs";
import PlantSpecs from "../../Components/PlantSpecs/PlantSpecs";
import Toast from "../../Components/Toast/Toast";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../Components/CustomInput/CustomInput";
import ProgressTracker from "../../Components/ProgressTracker/ProgressTracker";
import OutOfScopePopup from "../../Components/OutOfScopePopup/OutOfScopePopup";
import useIntersection from "../../Hooks/useIntersection";
import Step1Image from "../../Assets/Step1.png";
import Step2Image from "../../Assets/Step2.png";
import Step3Image from "../../Assets/Step3.png";
import Step4Image from "../../Assets/Step4.png";
import SaveProgressPopup from "../../Components/SaveProgressPopup/SaveProgressPopup";
import { PlantInfo } from "../../Enums/PlantInfo";
import BlacklistPopup from "../../Components/BlacklistPopup/BlacklistPopup";
import CustomMap from "../../Components/Map/Map";
import _ from "lodash";

function HomePage() {
  const rdbList = [
    "Biomass",
    "Offshore",
    "Waste to Energy",
    "Cement",
    "Power - Others",
    "Pulp & Paper",
    "Steel & Metals",
    "Oil & Gas - Upstream",
    "Petrochemicals/Refineries",
    "Transportation",
    "Hydrogen",
    "Gas to Power - Onshore",
    "Others",
  ];

  const [sector, setSector] = useState("");
  const [company, setCompany] = useState("");

  //Ref to check if current render is the first render
  //value === true ? first render : not first render
  //Used for proper triggering of the save progress popup
  const firstRenderTrackerRef = useRef(true);
  const fold2Ref = useRef(null);
  const fold3Ref = useRef(null);
  const fold4Ref = useRef(null);
  const [plants, setPlants] = useState([
    {
      name: "Plant 1",
      id: uuidv4(),
      location: "",
      country: "",
      isOffshore: false,
      isPlantSpecFilled: false,
      co2ReleasedPerYear: "",
      co2ToBeCaptured: "",
      utilitiesAvailable: [],
      downstreamManagementOfCO2: "",
      flueDetailsUrl: "",
      flueDetailsFileName: "",
    },
  ]);
  const [plantCoords, setPlantCoords] = useState([
    {
      coords: {},
      id: plants[0].id,
    },
  ]);
  const MAX_PLANT_COUNT = 8;
  const navigate = useNavigate();
  const [plantSpecifics, setPlantSpecifics] = useState("YES");
  const [isOutOfScopePopupOpen, toggleOutOfScopePopup] = useState(false);
  const [hasOutOfScopePopupBeenShown, setOutOfScopePopupBeenSeen] =
    useState(false);
  const [flueSpecifics, setFlueSpecifics] = useState("YES");
  const [formProgress, setFormProgress] = useState(0);
  const [isFormValid, setFormValid] = useState(false);
  const [areErrorsVisible, setErrorsVisible] = useState(false);
  const [hasSubmitBeenPressed, setSubmitPressed] = useState(false);
  const [locationIDObject, setlocationIDObject] = useState({
    id: plants[0].id,
    index: 0,
  });
  const queryParams = new URLSearchParams(window.location.search);

  //stuff for animations
  const [showScrollAlert, setShowScrollAlert] = useState(false);
  const [showFirstToast, setShowFirstToast] = useState(false);
  const [showSecondToast, setShowSecondToast] = useState(false);
  const scrollAlertRef = useRef();
  const inViewport = useIntersection(scrollAlertRef);
  const firstToastRef = useRef();
  const FTInViewport = useIntersection(firstToastRef);
  const [isExitPopupOpen, toggleExitPopup] = useState(false);
  const [overflowSetter, setOverflowSetter] = useState(false);
  const [isBlacklistPopupOpen, toggleBlacklistPopup] = useState(false);
  const [isSaveProgressPopupOpen, toggleSaveProgressPopup] = useState(false);
  const [hasSaveProgressPopupBeenShown, setSaveProgressPopupBeenShown] =
    useState(false);
  const [isFlueSpecErrorVisible, showFlueSpecError] = useState(false);
  const [isAddPlantEnabled, toggleAddPlantEnabled] = useState(true);

  const handleOffshoreButtonClick = (index) => {
    setPlants(
      plants.map((plant) =>
        plant.id === index
          ? {
              ...plant,
              isOffshore: !plant.isOffshore,
              location: plant.isOffshore ? "" : plant.location,
            }
          : plant
      )
    );
    setPlantCoords(
      plantCoords.map((plant) =>
        plant.id === index
          ? {
              ...plant,
              coords: {},
            }
          : plant
      )
    );
  };

  const handlePlantLocChange = (id, index, value) => {
    setPlants(
      plants.map((plant) =>
        plant.id === id ? { ...plant, location: value } : plant
      )
    );
    setlocationIDObject({
      id: id,
      index: index,
    });
  };

  //item can be a file blob or a plant object
  //if uploadedNow is true, then its a file blob
  const checkFileValidity = (item, uploadedNow) => {
    var name = "";
    if (uploadedNow) name = item?.name;
    else name = item?.flueDetailsFileName;
    if (name !== "") {
      var allowedExtensions = /(\.pdf|\.xls|\.doc|\.docx|\.xlsx)$/i;
      if (!allowedExtensions.exec(name)) {
        return false;
      }
      return true;
    }
    return false;
  };

  //index here is the unique id generated for each plant
  const handlePlantInfoChange = (index, attribute, value) => {
    switch (attribute) {
      case PlantInfo.LOC:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, location: value } : plant
          )
        );
        break;
      case PlantInfo.CRPY:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, co2ReleasedPerYear: value } : plant
          )
        );
        break;
      case PlantInfo.CTBC:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, co2ToBeCaptured: value } : plant
          )
        );
        break;
      case PlantInfo.UA:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, utilitiesAvailable: value } : plant
          )
        );
        break;
      case PlantInfo.DMOC:
        setPlants(
          plants.map((plant) =>
            plant.id === index
              ? { ...plant, downstreamManagementOfCO2: value }
              : plant
          )
        );
        break;
      case PlantInfo.IPSF:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, isPlantSpecFilled: value } : plant
          )
        );
        break;
      case PlantInfo.COORD:
        setPlants(
          plants.map((plant) =>
            plant.id === index ? { ...plant, country: value.country } : plant
          )
        );
        setPlantCoords(
          plantCoords.map((item) =>
            item.id === index ? { ...item, coords: value.coords } : item
          )
        );
        break;
      case PlantInfo.FD:
        if (value === "") {
          setPlants(
            plants.map((plant) =>
              plant.id === index
                ? { ...plant, flueDetailsUrl: "", flueDetailsFileName: "" }
                : plant
            )
          );
          return;
        }

        var val = checkFileValidity(value, true);
        if (!val) {
          showFlueSpecError(true);
          return;
        }
        const formData = new FormData();
        formData.append("file", value);

        axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}/api/upload-file`,
          data: formData,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.status === 200) {
              setPlants(
                plants.map((plant) =>
                  plant.id === index
                    ? {
                        ...plant,
                        flueDetailsUrl: response.data.url,
                        flueDetailsFileName: response.data.filename,
                      }
                    : plant
                )
              );
            }
          })
          .catch((e) => console.log(e));

        break;
      default:
        throw "Invalid attribute provided. Please provide a valid attribute of PlantInfo.";
    }
  };

  const formProgressPayload = () => {
    var currentPlants = [];

    for (let i = 0; i < plants.length; i++) {
      var obj = {};

      obj.locAddress = plants[i].location;
      obj.coords = plantCoords[i].coords;
      obj.isOffshore = plants[i].isOffshore;
      obj.co2Percentage = plants[i].co2ReleasedPerYear;
      obj.co2Ambition = plants[i].co2ToBeCaptured;
      obj.utilitiesAvailable = plants[i].utilitiesAvailable;
      obj.transferPlan = plants[i].downstreamManagementOfCO2;
      obj.flueGasSpecsFile = plants[i].flueDetailsUrl;

      currentPlants.push(obj);
    }
    // console.log(currentPlants);
    return {
      companyName: company,
      industrySector: sector,
      plants: currentPlants,
    };
  };

  //used to set bg if user decides to come back from
  //recommendation page, where the bg is #FFFFFF
  //and check if token is present
  useEffect(() => {
    document.body.style.backgroundColor = "#F5F7FA";
    if (queryParams.get("token")) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/form-progress`, {
          params: {
            token: queryParams.get("token"),
          },
        })
        .then((response) => {
          if (response.status === 200) {
            var tempPlants = [];
            var jsonedProgressData = JSON.parse(response.data.progressData);
            // console.log(jsonedProgressData);

            for (var i = 0; i < jsonedProgressData.plants.length; i++) {
              var obj = {};

              obj.name = "Plant " + (i + 1);
              obj.id = uuidv4();
              obj.location = jsonedProgressData.plants[i].locAddress;
              obj.coords = jsonedProgressData.plants[i].coords;
              obj.isOffshore = jsonedProgressData.plants[i].isOffshore;
              obj.co2ReleasedPerYear =
                jsonedProgressData.plants[i].co2Percentage;
              obj.co2ToBeCaptured = jsonedProgressData.plants[i].co2Ambition;
              obj.utilitiesAvailable =
                jsonedProgressData.plants[i].utilitiesAvailable;
              obj.downstreamManagementOfCO2 =
                jsonedProgressData.plants[i].transferPlan;
              obj.flueDetailsUrl =
                jsonedProgressData.plants[i].flueGasSpecsFile;
              obj.isPlantSpecFilled = false;
              if (obj.flueDetailsUrl === "") {
                obj.flueDetailsFileName = "";
              }

              tempPlants.push(obj);
            }
            var tempPlantCoords = tempPlants.map((item) => {
              return { coords: item.coords, id: item.id };
            });
            // console.log(tempPlantCoords);
            setCompany(jsonedProgressData.companyName);
            setSector(jsonedProgressData.industrySector);
            setPlants(tempPlants);
            setPlantCoords(tempPlantCoords);
          }
        });
    }
  }, []);

  const getCoords = (id, index) => {
    if (plants[index] === undefined) return;
    if (plants[index].location === "") {
      return;
    }
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/coords`, {
        params: {
          locAddress: plants[index].location,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          handlePlantInfoChange(id, PlantInfo.COORD, response.data);
          // console.log("got coords", response.data);
        }
        toggleAddPlantEnabled(true);
      })
      .catch((e) => {
        console.log(e);
        toggleAddPlantEnabled(true);
      });
  };

  useEffect(() => {
    if (firstRenderTrackerRef.current) return;
    toggleAddPlantEnabled(false);
    const delayDebounceFn = setTimeout(() => {
      getCoords(locationIDObject.id, locationIDObject.index);
    }, 1500);

    return () => clearTimeout(delayDebounceFn);
  }, [
    ...plants.map((item) => item.location),
    locationIDObject.id,
    locationIDObject.index,
  ]);

  useEffect(() => {
    if (firstRenderTrackerRef.current) {
      firstRenderTrackerRef.current = false;
      return;
    }
    if (hasSaveProgressPopupBeenShown || queryParams.get("token")) return;
    const delayDebounceFn = setTimeout(() => {
      toggleSaveProgressPopup(true);
      setSaveProgressPopupBeenShown(true);
    }, 2800);

    return () => clearTimeout(delayDebounceFn);
  }, [plants[0].location, plants.length]);

  //Used to toggle overflow property of the plant specific details section
  //Essential for good UX
  useEffect(() => {
    if (plantSpecifics === "YES")
      //IF YOU CHANGE THE DELAY DURATION HERE, BE SURE TO CHANGE IT IN THE
      //specific-info-section AND expand-specific-info-section SELECTORS
      //This is necessary in order to have a smooth transition when expanding/
      //collapsing the plant specifics cards in the third section of the form
      var overflowSetterTimer = setTimeout(() => setOverflowSetter(true), 500);
    else
      var overflowSetterTimer = setTimeout(() => setOverflowSetter(false), 800);
    return () => {
      clearTimeout(overflowSetterTimer);
    };
  }, [plantSpecifics]);

  const scrollToNextSection = (event, ref) => {
    event.preventDefault();
    ref.current.scrollIntoView({
      behavior: "smooth",
    });
  };

  const removePlantInput = (item) => {
    if (plants.length === 1) return;
    setPlants(plants.filter((curr) => curr.id !== item.id));
    setPlantCoords(plantCoords.filter((curr) => curr.id !== item.id));
    //the timeout is a bug fix. Removing the following statement
    //results in the user not being able to add another plant
    //after deleting one
    setTimeout(function () {
      toggleAddPlantEnabled(true);
    }, 1500);
  };

  const addPlantInput = () => {
    if (plants.length === MAX_PLANT_COUNT) return;
    var newId = uuidv4();
    setPlants((arr) => [
      ...arr,
      {
        name: `Plant ${arr.length + 1}`,
        id: newId,
        location: "",
        country: "",
        isOffshore: false,
        isPlantSpecFilled: false,
        co2ReleasedPerYear: "",
        co2ToBeCaptured: "",
        utilitiesAvailable: [],
        downstreamManagementOfCO2: "",
        flueDetailsUrl: "",
        flueDetailsFileName: "",
      },
    ]);
    setPlantCoords((arr) => [
      ...arr,
      {
        id: newId,
        coords: {},
      },
    ]);
  };

  // check if map is in window
  useEffect(() => {
    if (inViewport) {
      setShowScrollAlert(true);
      setShowSecondToast(true);
    }
    if (FTInViewport) setShowFirstToast(true);
  }, [inViewport, FTInViewport]);

  const checkIfCardsAreFilled = () => {
    if (plantSpecifics === "NO") return true;
    for (let i = 0; i < plants.length; i++) {
      const item = plants[i];
      if (
        item.co2ReleasedPerYear === "" ||
        item.co2ToBeCaptured === "" ||
        item.utilitiesAvailable.length === 0 ||
        item.downstreamManagementOfCO2 === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const isPlantCardFilled = (item) => {
    if (
      item.co2ReleasedPerYear === "" ||
      item.co2ToBeCaptured === "" ||
      item.utilitiesAvailable.length === 0 ||
      item.downstreamManagementOfCO2 === ""
    )
      return false;
    return true;
  };

  const checkIfFlueDetailsAreUploaded = () => {
    if (flueSpecifics === "NO") return true;
    for (let i = 0; i < plants.length; i++) {
      const item = plants[i];
      if (item.flueDetailsUrl === "") return false;
    }
    return true;
  };

  const checkIfFlueDetailsAreValid = () => {
    if (flueSpecifics === "NO") return true;
    for (let i = 0; i < plants.length; i++) {
      const item = plants[i];
      if (item.flueDetailsUrl === "") return false;
    }
    return true;
  };

  const isFlueCardFilledandValid = (item, id) => {
    if (item.flueDetailsUrl === "") {
      return false;
    }
    if (item.flueDetailsFileName) return true;
  };

  //Sets steps for the progress circle
  useEffect(() => {
    if (sector !== "") setFormProgress(1);

    if (company !== "" && (plants[0].isOffshore || plants[0].location !== ""))
      setFormProgress(2);

    if (checkIfCardsAreFilled()) setFormProgress(3);

    if (checkIfFlueDetailsAreUploaded()) setFormProgress(4);
  }, [sector, company, plants, plantSpecifics, flueSpecifics]);

  const shouldCardBeExpanded = (index) => {
    //separate field for first card, cause
    //we won't be checking if the previous card is full (since it is the first card)
    if (index === 0) {
      if (plants[index].isPlantSpecFilled) return false;
      else return true;
    }
    //check if current plant is filled, if it is,
    //collapse it
    if (plants[index].isPlantSpecFilled) {
      return false;
    }
    //check if previous plant is filled, if it is,
    //expand the current field
    if (plants[index - 1].isPlantSpecFilled) {
      return true;
    } else return false;
  };

  const isObjectEmpty = (obj) => {
    for (var i in obj) return false;
    return true;
  };

  const getMarkers = () => {
    var temp = [];
    for (var i = 0; i < plantCoords.length; i++)
      if (!isObjectEmpty(plantCoords[i].coords))
        temp.push(plantCoords[i].coords);
    return temp;
  };

  const markers = useMemo(() => getMarkers(), [plantCoords]);

  const validateConfig = () => {
    if (sector !== "")
      if (company !== "")
        if (checkIfCardsAreFilled())
          if (checkIfFlueDetailsAreValid()) {
            setFormValid(true);
            return;
          } else showFlueSpecError(true);

    setFormValid(false);
  };

  const handleConfigSubmit = (e) => {
    e.preventDefault();
    setSubmitPressed(true);
    validateConfig();
  };

  useEffect(() => {
    if (!isFormValid) {
      setErrorsVisible(true);
      return;
    }
    setErrorsVisible(false);
    if (!areErrorsVisible) {
      //TODO: remove card details from payload if empty;
      for (let i = 0; i < plants.length; i++)
        plants[i].coords = plantCoords[i].coords;
      console.log(plants);
      navigate("/recommendation", {
        state: { company: company, sector: sector, plants: plants },
      });
    }
  }, [isFormValid, areErrorsVisible]);

  return (
    <>
      {isBlacklistPopupOpen && (
        <BlacklistPopup
          isVisible={isBlacklistPopupOpen}
          toggleVisibility={toggleBlacklistPopup}
        />
      )}
      {isExitPopupOpen && (
        <ExitPopup
          isOpen={isExitPopupOpen}
          progressPayload={formProgressPayload()}
          setOpen={toggleExitPopup}
        />
      )}
      {isSaveProgressPopupOpen && (
        <SaveProgressPopup
          title="Let's save your progress!"
          subtitle="We will send a link to this configuration right to your inbox"
          isVisible={isSaveProgressPopupOpen}
          toggleVisibility={toggleSaveProgressPopup}
          progressPayload={formProgressPayload()}
        />
      )}
      {isOutOfScopePopupOpen && (
        <OutOfScopePopup
          isVisible={isOutOfScopePopupOpen}
          toggleVisibility={toggleOutOfScopePopup}
        />
      )}
      <Header callback={toggleExitPopup} value={isExitPopupOpen} />
      <form className="home-root">
        {/* First fold */}
        <section id="fold1">
          <p className="fm-title">
            <span className="sec-num">1</span>
            Select emission source industry sector
          </p>
          <p ref={firstToastRef} className="fm-subtitle ml-30">
            Based on the flue gas origin, we customize our plants to fit your
            needs. Please select the industrial sector that your flue gas comes
            from
          </p>
          <div className="rdb-wrapper">
            {rdbList &&
              rdbList.map((item, index) => {
                return (
                  <RDButton
                    key={index}
                    label={item}
                    currentSector={sector}
                    sectorCallback={setSector}
                  />
                );
              })}
            {showFirstToast && (
              <Toast
                animationDuration="12s"
                text="ACC technology has been tested on 8 different flue gases and has more than 50000 operating hours."
              />
            )}
          </div>
          {hasSubmitBeenPressed && areErrorsVisible && sector === "" && (
            <p className="err-hm err-sector">
              Please select an industry sector.
            </p>
          )}
          <CTAButton
            isDisabled={sector === ""}
            giveLeftMargin
            title="Next"
            onClick={(e) => scrollToNextSection(e, fold2Ref)}
          />
          <img src={Step1Image} alt="" className="abs-img step1" />
        </section>
        {/* Second fold */}
        <section id="fold2">
          {showScrollAlert && <ScrollAlert />}
          <div className="filler-div" ref={fold2Ref} />
          <p className="fm-title frm-sec-fold">
            <span className="sec-num">2</span>
            Provide your plant locations
          </p>
          <div className="sec-fold-content">
            <p className="fm-subtitle">
              Location of your plant plays a role in what offerings we have
              available. Please use this search to indicate one or more plants
              you consider for carbon capture.
            </p>
            <span ref={scrollAlertRef} className="org-input-wrapper">
              <input
                placeholder="Organization"
                value={company}
                onChange={(event) => setCompany(event.target.value)}
                className="org-input"
              />
            </span>
            {hasSubmitBeenPressed && areErrorsVisible && company === "" && (
              <p className="err-hm">Please enter organization name.</p>
            )}
            <CustomMap className="map" markers={markers} />
            <div className="plant-inps-wrapper">
              {plants.map((item, idx) => {
                return (
                  <>
                    <div key={item.id} className="org-input-wrapper">
                      <input
                        placeholder={`${
                          item.isOffshore
                            ? "Offshore"
                            : "Plant " + (idx + 1) + " location"
                        }`}
                        onChange={(e) =>
                          handlePlantLocChange(item.id, idx, e.target.value)
                        }
                        value={item.isOffshore ? "Offshore" : item.location}
                        disabled={item.isOffshore}
                        className="org-input smaller-input"
                      />
                      <img
                        src={Delete}
                        alt=""
                        onClick={() => removePlantInput(item)}
                        className={`delete-logo ${
                          idx === 0 ? "hd-add-wrapper" : ""
                        }`}
                      />
                    </div>
                    {hasSubmitBeenPressed &&
                      areErrorsVisible &&
                      item.location === "" &&
                      !item.isOffshore && (
                        <p className="err-hm">
                          Please enter location of the plant or mark as
                          offshore.
                        </p>
                      )}
                  </>
                );
              })}
            </div>
            <div className="inp-byline-actions">
              <CustomInput
                onClick={() =>
                  handleOffshoreButtonClick(plants[plants.length - 1].id)
                }
                label="My plant is offshore"
                isClicked={plants[plants.length - 1].isOffshore}
              />
              <div
                className={`add-wrapper ${
                  plants.length === MAX_PLANT_COUNT ? "hd-add-wrapper" : ""
                } ${!isAddPlantEnabled ? "dis-addplant" : ""}`}
                onClick={() => isAddPlantEnabled && addPlantInput()}
              >
                <img src={AddPlant} alt="" className="addplant-logo" />
                <span>Add plant</span>
              </div>
            </div>
            {showSecondToast && (
              <Toast
                animationDuration="16s"
                text="Our carbon capture as a service offering provides the full value chain with capture, transport and storage in a pay per tonne model. It is available for selected countries, going beyond just offering a carbon capture plant."
              />
            )}
            <CTAButton
              isDisabled={
                company === ""
                  ? true
                  : plants[0].isOffshore
                  ? false
                  : plants[0].location === ""
                  ? true
                  : false
              }
              title="Next"
              onClick={(e) => scrollToNextSection(e, fold3Ref)}
            />
          </div>
          <img src={Step2Image} alt="" className="abs-img step2" />
        </section>
        {/* Third fold */}
        <section id="fold3">
          <div className="filler-div" ref={fold3Ref} />
          <p className="fm-title frm-sec-fold">
            <span className="sec-num">3</span>
            Are you ready to share some specifics around your plants?
          </p>
          <div className="sec-fold-content">
            <div className="fm-subtitle">
              In order to configure the right plant, we will be asking questions
              around emission source and plans you have for utilize and store
              the CO<sub>2</sub>.
            </div>
            <div className="thr-rdb-wrapper">
              {/* <div className="btns-wrapper">
                <RDButton
                  label="YES"
                  currentSector={plantSpecifics}
                  miniVersion={true}
                  sectorCallback={setPlantSpecifics}
                />
                <RDButton
                  label="NO"
                  currentSector={plantSpecifics}
                  miniVersion={true}
                  sectorCallback={setPlantSpecifics}
                />
              </div> */}
              <div
                style={
                  overflowSetter
                    ? { overflow: "visible" }
                    : { overflow: "hidden" }
                }
                className={`specific-info-section ${
                  plantSpecifics === "YES" ? "expand-specific-info-section" : ""
                }`}
              >
                {plants.map((item, index) => {
                  return (
                    <>
                      <PlantSpecs
                        key={item.id}
                        index={item.id}
                        handlePlantDetailChange={handlePlantInfoChange}
                        plantDetail={plants[index]}
                        hideMarginTop={index === 0}
                        isExpanded={shouldCardBeExpanded(index)}
                      />
                      {hasSubmitBeenPressed &&
                        areErrorsVisible &&
                        !isPlantCardFilled(item) && (
                          <p className="err-hm err-hm-final">
                            Ensure that all fields of the card are filled.
                          </p>
                        )}
                    </>
                  );
                })}
              </div>
            </div>
            <CTAButton
              isDisabled={plantSpecifics === "" ? true : false}
              title="Next"
              onClick={(e) => scrollToNextSection(e, fold4Ref)}
            />
          </div>
          <img src={Step3Image} alt="" className="abs-img step2" />
        </section>
        {/* Fourth fold */}
        <section id="fold4">
          <div className="filler-div" ref={fold4Ref} />
          <p className="fm-title frm-sec-fold">
            <span className="sec-num">4</span>
            Would you be sharing any Flue gas specifications?
          </p>
          <p className="fm-subtitle ml-30">
            Flue gas specifications are key to evaluate if our technology can be
            used. Can you share those with us now?
          </p>
          <div className="sec-fold-content">
            <div className="thr-rdb-wrapper">
              <div className="btns-wrapper">
                <RDButton
                  label="YES"
                  currentSector={flueSpecifics}
                  miniVersion={true}
                  sectorCallback={setFlueSpecifics}
                />
                <RDButton
                  label="NO"
                  currentSector={flueSpecifics}
                  miniVersion={true}
                  sectorCallback={setFlueSpecifics}
                />
              </div>
              <div
                className={`${
                  flueSpecifics === "YES"
                    ? "expand-flue-info-inp"
                    : "no-exp-flue"
                }`}
              >
                {plants.map((item, idx) => {
                  return (
                    <>
                      <FlueSpecs
                        key={item.id}
                        plantName={`Plant ${idx + 1}`}
                        id={item.id}
                        flueDetails={item.flueDetailsFileName}
                        flueLink={item.flueDetailsUrl}
                        setFlueDetails={handlePlantInfoChange}
                        showFileInvalidErr={
                          isFlueSpecErrorVisible &&
                          !checkFileValidity(item, false)
                        }
                        hasSubmitBeenPressed={hasSubmitBeenPressed}
                        plantAddress={
                          item.isOffshore ? "Offshore" : item.location
                        }
                        withByline={idx === 0}
                      />
                    </>
                  );
                })}
              </div>
            </div>
            <CTAButton
              isDisabled={flueSpecifics === "" ? true : false}
              title="All set! Let's look at the options"
              onClick={(event) => {
                handleConfigSubmit(event);
              }}
            />
            {areErrorsVisible && hasSubmitBeenPressed && (
              <p className="err-hm">
                Ensure that all fields have valid inputs before submitting.
              </p>
            )}
          </div>
          <img src={Step4Image} alt="" className="abs-img step2 step4" />
        </section>
      </form>
      <ProgressTracker value={formProgress} />
    </>
  );
}

export default HomePage;
