import { useEffect, useRef, useState } from "react";
import Cross from "../../Assets/close.svg";
import axios from "axios";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import "./SaveProgressPopup.css";

function SaveProgressPopup({
  isVisible,
  toggleVisibility,
  progressPayload,
  sendEmail,
  title,
  subtitle,
  plantRecomm,
}) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const coreRef = useRef(null);
  const apiPayload = {};
  useOutsideClickNotifier(coreRef, toggleVisibility);

  const [showFormErrors, setFormErrors] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [hasSubmitBeenPressed, setPressed] = useState(false);
  const [isSubmitDisabled, toggleSubmitDisabled] = useState(false);

  //Depending on sendEmail, form and return payload
  const getPayload = () => {
    apiPayload.email = email;
    apiPayload.firstName = firstName;
    apiPayload.lastName = lastName;
    if (sendEmail) {
      apiPayload.uid = localStorage.getItem("InteractionID");
      apiPayload.plantRecomm = plantRecomm;
    } else apiPayload.progressData = progressPayload;
  };

  useEffect(() => {
    if (isFormValid) {
      toggleSubmitDisabled(true);
      getPayload();
      axios({
        method: "post",
        url: sendEmail
          ? `${process.env.REACT_APP_API_URL}/api/email-recomm`
          : `${process.env.REACT_APP_API_URL}/api/form-progress`,
        data: apiPayload,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            if (!sendEmail)
              localStorage.setItem("ContactID", response.data.contactId);
            toggleSubmitDisabled(false);
            toggleVisibility(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toggleSubmitDisabled(false);
        });
    }
  }, [isFormValid]);

  const checkFormValidity = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      !email.match(/^\S+@\S+\.\S+$/)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hasSubmitBeenPressed) {
      setPressed(true);
      setFormErrors(true);
    }
    checkFormValidity();
    if (!isFormValid) {
      return;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "initial");
  }, []);

  return (
    <>
      <div className="spp-overlay" style={{ top: window.scrollY }}>
        <form
          ref={coreRef}
          onSubmit={(e) => handleSubmit(e)}
          className="spp-root"
        >
          <p className="spp-title">
            <img className="spp-cross hd-spp-cross" src={Cross} alt="" />
            {title}
            <img
              onClick={() => toggleVisibility(!isVisible)}
              className={`spp-cross ${sendEmail ? "smails" : ""}`}
              src={Cross}
              alt=""
            />
          </p>
          <p className="spp-subt">{subtitle}</p>
          <p className="spp-label">First Name</p>
          <input
            type="text"
            placeholder="John"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="spp-inp"
          />
          {showFormErrors && firstName.length === 0 && (
            <span className="err-spp">First name cannot be empty</span>
          )}
          <p className="spp-label">Last Name</p>
          <input
            type="text"
            placeholder="Doe"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="spp-inp"
          />
          {showFormErrors && lastName.length === 0 && (
            <span className="err-spp">Last name cannot be empty</span>
          )}
          <p className="spp-label">Email</p>
          <input
            type="email"
            placeholder="john@gmail.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="spp-inp"
          />
          {showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && (
            <span className="err-spp">Invalid email id</span>
          )}
          <button
            type="submit"
            disabled={isSubmitDisabled}
            className="spp-sub-btn"
          >
            Done
          </button>
        </form>
      </div>
    </>
  );
}

export default SaveProgressPopup;
