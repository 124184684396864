import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import React, { useEffect, useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

const MapContainer = React.memo((props) => {
  const [map, setMap] = useState();
  // const [center, setCenter] = useState([10, 10]);
  var bounds = new props.google.maps.LatLngBounds();

  const setMapObj = (mapProps, map) => {
    setMap(map);
  };

  useDeepCompareEffect(() => {
    // console.log(props.markers);
    if (props.markers.length < 1) return;
    // setCenter(props.markers[props.markers.length - 1]);
    //this is to zoom out the map by bit;
    //we provide bounds but not the marker
    if (props.markers.length === 1) {
      bounds.extend(
        new props.google.maps.LatLng(props.markers[0].lat, props.markers[0].lng)
      );
      bounds.extend(
        new props.google.maps.LatLng(
          props.markers[0].lat - 1,
          props.markers[0].lng - 1
        )
      );
      map && map.fitBounds(bounds);
      return;
    }
    for (var i = 0; i < props.markers.length; i++) {
      bounds.extend(
        new props.google.maps.LatLng(props.markers[i].lat, props.markers[i].lng)
      );
    }
    if (map) {
      map.fitBounds(bounds);
    }
  }, [props.markers, map]);

  return (
    <div className={props.className}>
      <Map
        google={props.google}
        style={{ borderRadius: "10px" }}
        // center={center}
        // center={props.markers.length < 1 ? { lat: 59.913, lng: 10.75 } : {}}
        initialCenter={{ lat: 59.913, lng: 10.75 }}
        onReady={setMapObj}
        zoom={7}
      >
        {props.markers.map((item, index) => {
          if (item.lat && item.lng)
            return (
              <Marker key={index} position={{ lat: item.lat, lng: item.lng }} />
            );
        })}
      </Map>
    </div>
  );
});

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GMAPS_API_KEY,
})(MapContainer);
