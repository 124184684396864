import "./PlantSpecs.css";
import InfoDialog from "../InfoDialog/InfoDialog";
import Down from "../../Assets/down.svg";
import Info from "../../Assets/info.svg";
import { useState, useEffect, memo } from "react";
import SingleDropdownSelect from "../SingleDropdownSelect/SingleDropdownSelect";
import MultiDropdownSelect from "../MultiDropdownSelect/MultiDropdownSelect";
import { PlantInfo } from "../../Enums/PlantInfo";

function PlantSpecs({
  plantDetail,
  index,
  isExpanded,
  hideMarginTop,
  handlePlantDetailChange,
}) {
  const [overflowVisible, setOverflowVisible] = useState(isExpanded);
  const [isBig, toggleExpansion] = useState(isExpanded);
  const transferOptions = [
    "Compressed into pipeline",
    "Utilized directly in gas phase",
    "Liquified into tank/pipeline",
    "Other",
    "Don't know yet",
  ];

  useEffect(() => {
    if (
      plantDetail.co2ReleasedPerYear !== "" &&
      plantDetail.co2ToBeCaptured !== "" &&
      plantDetail.utilitiesAvailable.length !== 0 &&
      plantDetail.downstreamManagementOfCO2 !== ""
    )
      handlePlantDetailChange(index, PlantInfo.IPSF, true);
  }, [
    plantDetail.co2ReleasedPerYear,
    plantDetail.co2ToBeCaptured,
    plantDetail.utilitiesAvailable,
    plantDetail.downstreamManagementOfCO2,
  ]);

  useEffect(() => {
    toggleExpansion(isExpanded);
  }, [isExpanded]);

  useEffect(() => {
    if (isBig)
      //IF YOU CHANGE THE DELAY DURATION HERE, BE SURE TO CHANGE IT IN THE
      //ps-root AND exp-psr SELECTORS
      //This is necessary in order to have a smooth transition when expanding/
      //collapsing the plant specifics cards in the third section of the form
      var overflowSetterTimer = setTimeout(() => setOverflowVisible(true), 500);
    else
      var overflowSetterTimer = setTimeout(() => setOverflowVisible(false), 10);
    return () => {
      clearTimeout(overflowSetterTimer);
    };
  }, [isBig]);

  return (
    <div
      style={overflowVisible ? { overflow: "visible" } : { overflow: "hidden" }}
      className={`ps-root ${isBig ? "exp-psr" : ""} ${
        hideMarginTop ? "mt-0" : ""
      }`}
    >
      <div className="flex-wrapper">
        <span>
          <p id="psec-title">{plantDetail.name}</p>
          <p className="spec-info-sec-subtitle">
            {plantDetail.isOffshore
              ? "Offshore"
              : plantDetail.location === ""
              ? "Plant location"
              : plantDetail.location}
          </p>
        </span>
        <img
          src={Down}
          className={`dwn-ar ${!isBig ? "" : "ar-invert"}`}
          onClick={() => toggleExpansion(!isBig)}
          alt=""
        />
      </div>
      <div className="mob-divider" />
      <br />
      {/* stuff for mobile */}
      <div className="mob-spec">
        <p className="msi-label">
          How many tonnes of CO<sub>2</sub> emissions per year?
        </p>
        <input
          type="number"
          onWheelCapture={(e) => e.target.blur()}
          tabIndex={isBig ? "0" : "-1"}
          className="msi-det"
          value={plantDetail.co2ReleasedPerYear}
          onChange={(e) => {
            handlePlantDetailChange(index, PlantInfo.CRPY, e.target.value);
          }}
        />
        <p className="msi-label">How many tonnes do you plan to capture?</p>
        <input
          type="number"
          onWheelCapture={(e) => e.target.blur()}
          tabIndex={isBig ? "0" : "-1"}
          className="msi-det"
          value={plantDetail.co2ToBeCaptured}
          onChange={(e) => {
            handlePlantDetailChange(index, PlantInfo.CTBC, e.target.value);
          }}
        />
        <p className="msi-label">What utilities are available at the plant?</p>
        <div className="mdsr-wrapper">
          <MultiDropdownSelect
            index={index}
            currentValues={plantDetail.utilitiesAvailable}
            fixValues={handlePlantDetailChange}
            isMobile
          />
        </div>
        <p className="msi-label mt-29">
          What is your plan for downstream management of CO<sub>2</sub>?
        </p>
        <SingleDropdownSelect
          index={index}
          currentValue={plantDetail.downstreamManagementOfCO2}
          fixValue={handlePlantDetailChange}
          isMobile
          options={transferOptions}
        />
      </div>
      {/* stuff for desktop*/}
      <div className="specific-info-inp">
        <span className="sii-text">
          How many tonnes of CO<sub className="sub-lh">2</sub> <br /> emissions
          per year?
        </span>
        {hideMarginTop && <img src={Info} className={`info-ico`} alt="" />}
        <InfoDialog className="show-db" />
        <input
          type="number"
          onWheelCapture={(e) => e.target.blur()}
          tabIndex={isBig ? "0" : "-1"}
          value={plantDetail.co2ReleasedPerYear}
          onChange={(e) => {
            handlePlantDetailChange(index, PlantInfo.CRPY, e.target.value);
          }}
          className="specify-detail-inp"
        />
      </div>
      <div className="specific-info-inp">
        <span className="sii-text">
          How many tonnes of CO<sub className="sub-lh">2</sub> do <br /> you
          plan to capture?
        </span>
        <input
          type="number"
          onWheelCapture={(e) => e.target.blur()}
          tabIndex={isBig ? "0" : "-1"}
          value={plantDetail.co2ToBeCaptured}
          onChange={(e) => {
            handlePlantDetailChange(index, PlantInfo.CTBC, e.target.value);
          }}
          className="specify-detail-inp"
        />
      </div>
      <div className="specific-info-inp">
        <span className="sii-text">
          What utilities are available at <br /> the plant?
        </span>
        <MultiDropdownSelect
          index={index}
          currentValues={plantDetail.utilitiesAvailable}
          fixValues={handlePlantDetailChange}
        />
      </div>
      <div className="specific-info-inp">
        <span className="sii-text">
          What is your plan for downstream <br /> management of CO
          <sub className="sub-lh">2</sub>?
        </span>
        <SingleDropdownSelect
          index={index}
          currentValue={plantDetail.downstreamManagementOfCO2}
          fixValue={handlePlantDetailChange}
          options={transferOptions}
        />
      </div>
    </div>
  );
}

export default PlantSpecs;
