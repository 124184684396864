import "./OutOfScopePopup.css";
import { useEffect, useRef } from "react";
import Cross from "../../Assets/close.svg";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";

function OutOfScopePopup({ isVisible, toggleVisibility }) {
	const coreRef = useRef(null);
	useOutsideClickNotifier(coreRef, toggleVisibility);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => document.body.style.overflow = "initial";
	}, [])
	
	return (
		<>
		<div className="oosp-overlay" style={{ top: window.scrollY }}>
			<div ref={coreRef} className="oosp-root">
				<p className="oosp-title">
						These are very exciting times<br className="ns-breaker"/> in the carbon capture market.
					<img onClick={() => toggleVisibility(false)} className="oosp-cross" src={Cross} alt=""/>
				</p>
				<p className="oosp-subt">
					with lots of interest and encouraging movements being shown all over the world.
					<br/>
					<br/>
					As a result, we have received a large influx of enquiries from different regions and for different applications. In order to keep up the standard of our deliveries and quality of our products, we have decided to focus on certain regions where the political and regulatory drivers make it possible for us to land projects in the short term. 
					<br/>
					<br/>
					You have selected a region where we currently do not offer our services. If you have locations in Europe or North America, please go back and indicate those.
					If not, you can leave your details below and ask for a call back.
				</p>
				<div className="oosp-sub-btn" onClick={() => toggleVisibility(false)}>Done</div>
			</div>
		</div>
		</>
	)
}

export default OutOfScopePopup;