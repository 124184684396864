import { useEffect } from "react";
/* Hook that alerts clicks outside of the passed ref */

function useOutsideClickNotifier(ref, action) {
	useEffect(() => {
		function handleOutsideClick(event) {
			if(ref.current && !ref.current.contains(event.target)) {
				//close popup
				action(false);
			}
		}

		document.addEventListener("mousedown", handleOutsideClick);
		return () => document.removeEventListener("mousedown", handleOutsideClick);
	}, [ref]);
}

export default useOutsideClickNotifier;