import { useState, useEffect } from "react";
import ExitPopup from "../../Components/ExitRecommPopup/ExitRecommPopup";
import Header from "../../Components/Header/Header";
import PlantRec from "../../Components/PlantRec/PlantRec";
import Plant from "../../Assets/plant.png";
import Info from "../../Assets/info.svg";
import Mail from "../../Assets/mail.svg";
import axios from "axios";
import "./RecommendationPage.css";
import BookMeeting from "../../Components/BookMeeting/BookMeeting";
import Loading from "../../Components/Loading/Loading";
import ViewPlantSpecs from "../../Components/ViewPlantSpecs/ViewPlantSpecs";
import { useLocation } from "react-router-dom";
import SaveProgressPopup from "../../Components/SaveProgressPopup/SaveProgressPopup";
import _ from "lodash";

function RecommendationPage() {
  const payload = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const [isExitPopupOpen, setExitPopupOpen] = useState(false);
  const [isBookMeetingPopupOpen, setBookMeetingPopupOpen] = useState(false);
  const [recomm, setRecomm] = useState("");
  const [arePlantSpecsBeingViewed, viewPlantSpecs] = useState(false);
  const [isEmailPopupOpen, toggleEmailPopup] = useState(false);
  var apiPayload = {};

  useEffect(() => {
    if (recomm === "error") document.body.style.backgroundColor = "#f5f7fa";
  }, [recomm]);

  const formPayload = () => {
    if (payload.state === null) {
      setRecomm("error");
      return;
    }
    apiPayload.industrySector = payload.state.sector;
    apiPayload.companyName = payload.state.company;

    if (localStorage.getItem("ContactID"))
      apiPayload.contactId = localStorage.getItem("ContactID").toString();
    apiPayload.plants = [];

    for (let i = 0; i < payload.state.plants.length; i++) {
      var obj = {};
      const element = payload.state.plants[i];

      obj.locAddress = element.location;
      obj.coords = element.coords;
      obj.country = element.country;
      obj.isOffshore = element.isOffshore;
      if (element.co2ReleasedPerYear !== "")
        obj.co2Percentage = element.co2ReleasedPerYear;
      if (element.co2ToBeCaptured !== "")
        obj.co2Ambition = element.co2ToBeCaptured;
      if (element.utilitiesAvailable.length !== 0)
        obj.utilitiesAvailable = element.utilitiesAvailable;
      if (element.downstreamManagementOfCO2 !== "")
        obj.transferPlan = element.downstreamManagementOfCO2;
      obj.flueGasSpecsFile = element.flueDetailsUrl;

      apiPayload.plants.push(obj);
    }
    // console.log("payload", apiPayload);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    formPayload();
    var url = process.env.REACT_APP_API_URL;
    axios({
      method: "post",
      url: `${url}/api/get-recomm`,
      data: apiPayload,
      headers: {
        "Content-Type": "application/json",
      },
      proxy: false,
    })
      .then((response) => {
        if (response.status === 200) {
          // console.log(response.data);
          localStorage.setItem("InteractionID", response.data.uid);
          setRecomm(response.data);
        }
      })
      .catch((e) => {
        console.log(e);
        setRecomm("error");
      });
  }, []);

  const getPayload = () => {
    var payload = {};

    payload.name = recomm.plants[currentTab].name;
    payload.location = recomm.plants[currentTab].locAddress;
    payload.CRPY = recomm.plants[currentTab].co2Percentage;
    payload.CTBC = recomm.plants[currentTab].co2Ambition;
    payload.DMOC = recomm.plants[currentTab].transferPlan;
    payload.UA = recomm.plants[currentTab].utilitiesAvailable;

    return payload;
  };

  const getPlantsList = () => {
    var pList = [];
    for (let i = 0; i < recomm.plants.length; i++) {
      var payload = {};
      const element = recomm.plants[i];

      payload.name = element.name;
      payload.locAddress = element.locAddress;
      payload.co2Percentage = element.co2Percentage;
      payload.co2Ambition = element.co2Ambition;
      payload.transferPlan = element.transferPlan;
      payload.utilitiesAvailable = element.utilitiesAvailable;

      pList.push(payload);
    }

    return pList;
  };

  return (
    <>
      {isExitPopupOpen ? (
        <ExitPopup
          isOpen={isExitPopupOpen}
          plantRecommList={getPlantsList()}
          setOpen={setExitPopupOpen}
        />
      ) : (
        <></>
      )}
      {isBookMeetingPopupOpen ? (
        <BookMeeting
          isOpen={isBookMeetingPopupOpen}
          setOpen={setBookMeetingPopupOpen}
          companyName={recomm.companyName}
          recommId={recomm.plants[currentTab].recommId}
        />
      ) : (
        <></>
      )}
      {arePlantSpecsBeingViewed && (
        <ViewPlantSpecs
          plant={getPayload()}
          isVisible={arePlantSpecsBeingViewed}
          toggleVisibility={viewPlantSpecs}
        />
      )}
      {isEmailPopupOpen && (
        <SaveProgressPopup
          sendEmail
          plantRecomm={recomm.plants[currentTab]}
          isVisible={isEmailPopupOpen}
          toggleVisibility={toggleEmailPopup}
          title="Right to your inbox!"
          subtitle="We will send the recommendations to you"
        />
      )}
      <Header
        whiteBG={recomm === ""}
        value={isExitPopupOpen}
        hideCross={recomm === "" || recomm === "error"}
        callback={setExitPopupOpen}
      />
      {recomm === "" ? (
        <Loading />
      ) : recomm !== "error" ? (
        <div className="rp-content">
          <section className="sec1-pr">
            <p className="rec-title">
              <span className="bold-title-rec">Our recommendations</span>
              <br />
            </p>
            <div className="r-tabs-list">
              {recomm.plants.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => setCurrentTab(index)}
                    className={`tab-wrapper ${
                      currentTab === index ? "active-tab" : ""
                    }`}
                  >
                    {recomm.plants[index].name}
                  </div>
                );
              })}
            </div>
          </section>
          <section className="plant-rec-sec">
            <div className="action-line-pr">
              <div className="bold-addr">
                <span id="addr">
                  {recomm.plants[currentTab].isOffshore
                    ? "Offshore"
                    : recomm.plants[currentTab].locAddress}
                </span>
                <img
                  src={Mail}
                  alt=""
                  onClick={() => toggleEmailPopup(true)}
                  className="mail"
                />
              </div>
              <div
                onClick={() => viewPlantSpecs(true)}
                className="desktop-vs view-spec-wrapper"
              >
                <img src={Info} alt="" className="info-spec" />
                View mentioned specs
              </div>
            </div>
            <div
              onClick={() => viewPlantSpecs(true)}
              className="mob-vs view-spec-wrapper"
            >
              <img src={Info} alt="" className="info-spec" />
              View mentioned specs
            </div>
            <div>
              {recomm.plants[currentTab].offers.map((item, index) => {
                return (
                  <PlantRec
                    key={index}
                    bookMeetingCallback={setBookMeetingPopupOpen}
                    isBookMeetingPopupOpen={isBookMeetingPopupOpen}
                    offer={item}
                  />
                );
              })}
            </div>
            <div style={{ height: "50px" }} />
          </section>
        </div>
      ) : (
        <div className="err-text-msg sec1-pr">
          <p>
            There was an error fetching the recommendations.
            <br />
            Please try again.
          </p>
        </div>
      )}
    </>
  );
}

export default RecommendationPage;
