import "./Loading.css";
import Plant from "../../Assets/plant2.png";

function Loading() {
	document.body.style.backgroundColor = '#FFFFFF';
	return (
		<div className="load-root">
			<div className="load-cont">
				<div className="loader" />
				<p className="loading-text">We are fetching <br/><span className="load-bold">the most suitable model</span><br/>for your plants</p>
			</div>
			<img src={Plant} className="load-image" alt="" />
		</div>
	)
}

export default Loading;