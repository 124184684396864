import { useState, useRef } from "react";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import DownArrow from "../../Assets/small-dwn-arrow.svg";
import Tick from "../../Assets/tick.svg";
import "./MultiDropdownSelect.css";
import { PlantInfo } from "../../Enums/PlantInfo";

function MultiDropdownSelect({ index, isMobile, currentValues, fixValues }) {
	const [isOpen, toggleOpen] = useState(false);
	const mdsRef = useRef(null);
	useOutsideClickNotifier(mdsRef, toggleOpen);
	const options = ["Electricity", "Low pressure steam", "Water cooling"];

	const handleClick = (item) => {
		if(currentValues.includes(item)) {
			fixValues(index, PlantInfo.UA, currentValues.filter(label => label !== item));
		} else {
			fixValues(index, PlantInfo.UA, [...currentValues, item]);
		}
	}

	return (
		<div ref={mdsRef} className={`mds-root ${isMobile ? 'mds-hide' : ''} ${isOpen ? 'exp-mds' : ''}`}>
			<span onClick={() => toggleOpen(!isOpen)} className={`mds-select ${currentValues.length === 0 ? 'mds-pcolor' : ''}`}>
				{`${currentValues.length === 0 ? 'Select' : currentValues.length + ' selected'}`}
				<img src={DownArrow} alt="" className="mds-exp-icon"/>
			</span>
			<div>
				{
					options.map((item, i) => {
						return (
							<span className="mds-exp-list-item" onClick={() => handleClick(item)} key={i}>
								<div className={`mds-rd-green ${currentValues.includes(item) ? 'mds-selected' : '' }`}>
									<img src={Tick} alt="" className={` mds-tick ${currentValues.includes(item) ? '' : 'hd-mds-gtick'}`}/>
								</div>
								{item}
							</span>
						)
					})
				}
			</div>
		</div>
	)
}

export default MultiDropdownSelect;