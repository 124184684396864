import "./ViewPlantSpecs.css";
import Cross from "../../Assets/close.svg";
import { useEffect, useRef } from "react";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";

function ViewPlantSpecs({ plant, isVisible, toggleVisibility }) {
  const coreRef = useRef(null);
  useOutsideClickNotifier(coreRef, toggleVisibility);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "initial");
  }, []);

  return (
    <>
      <div className="vps-overlay" style={{ top: window.scrollY }}>
        <div className="vps-root" ref={coreRef}>
          <p className="vps-title">
            <img className="vps-cross hd-vps-cross" src={Cross} alt="" />
            {plant.name}
            <img
              onClick={() => toggleVisibility(!isVisible)}
              className="vps-cross"
              src={Cross}
              alt=""
            />
          </p>
          <p className="vps-subt">{plant.location}</p>
          {/* Mobile screens component */}
          <div className="mob-plant-details">
            <p className="mpd-label">
              What is the total amount of CO<sub>2</sub> emissions per year?
            </p>
            <div className="mpd-det">{plant.CRPY}</div>
            <p className="mpd-label">How much do you plan to capture?</p>
            <div className="mpd-det">{plant.CTBC}</div>
            <p className="mpd-label">
              What utilities are available at the plant?
            </p>
            <div className="mpd-det">{plant.UA.length + " selected"}</div>
            <p className="mpd-label">
              What is your plan for downstream management of CO<sub>2</sub>?
            </p>
            <div className="mpd-det">{plant.DMOC}</div>
          </div>
          {/* Desktop screens component */}
          <div className="plant-det-crd">
            <p className="vps-desc-text">
              What is the total amount of CO<sub>2</sub>
              <br /> emissions per year?
            </p>

            <div className="val-wrapper">{plant.CRPY}</div>
          </div>
          <div className="plant-det-crd">
            <p className="vps-desc-text">How much do you plan to capture?</p>
            <div className="val-wrapper">{plant.CTBC}</div>
          </div>
          <div className="plant-det-crd">
            <p className="vps-desc-text">
              What utilities are available at the
              <br /> plant?
            </p>
            <div className="val-wrapper">{plant.UA.length + " selected"}</div>
          </div>
          <div className="plant-det-crd mb-50">
            <p className="vps-desc-text">
              What is your plan for downstream
              <br /> management of CO<sub>2</sub>?
            </p>
            <div className="val-wrapper">{plant.DMOC}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewPlantSpecs;
