import "./BlacklistPopup.css";
import { useEffect, useState } from "react";

function BlacklistPopup({ isVisible, toggleVisibility }) {
	const [email, setEmail] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [contactNum, setContactNum] = useState('');
	const [showFormErrors, setFormErrors] = useState(false);
	const [isFormValid, setFormValid] = useState(false);
	const [isButtonClicked, setBtnClicked] = useState(false);
	const [isFormSubmitted, setFormSubmitted] = useState(false);
	const [hasSubmitBeenPressed, setPressed] = useState(false);

	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => document.body.style.overflow = "initial";
	}, [])

	useEffect(() => {
		if(isFormValid) {
			var payload = {
				email: email,
				companyName: companyName,
				contact: contactNum
			}
			setFormSubmitted(true)
			// axios({
			// 	method: 'post',
			// 	url: 'http://localhost:3002/api/blacklist',
			// 	data: payload,
			// 	headers: {
			// 	'Content-Type': 'application/json'
			// 	} 
			// }).then((response) => {
			// 	if(response.status === 200)
			// 		setFormSubmitted(true)
			// }).catch(e => console.log(e));
		}
	}, [isFormValid])

	const checkFormValidity = () => {
		if(companyName.length === 0 || contactNum.length === 0 || !email.match(/^\S+@\S+\.\S+$/)) {
			setFormValid(false);
			return;
		} 
		setFormValid(true);
	}

	const handleSubmit = () => {
		if(!hasSubmitBeenPressed) {
			setPressed(true);
			setFormErrors(true);
		}
		checkFormValidity();
		if(!isFormValid) {
			return;
		}
	}

	return (
		<>
		<div className="blp-overlay" style={{ top: window.scrollY }}>
			<div className="blp-root">
				<p className="blp-title">
					{ !isButtonClicked ? 'Thank you!' : isFormSubmitted ? 'Thank you for submitting!' : 'Your details' }
				</p>
				{ !isButtonClicked && <p className="blp-subt mbl-20">
					Your business is important for us. Given your feedback, we suggest you fill in your details and
					we will let one of our engineers get back to you.
				</p>}
				{
					isButtonClicked && !isFormSubmitted && 
						<>
							<p className="blp-label">Company Name</p>
							<input type="text" placeholder="Oscorp industries" value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="blp-inp"/>
							{ showFormErrors && companyName.length === 0 && <span className="err-blp">Company name cannot be empty</span> }
							<p className="blp-label">Contact number</p>
							<input type="number" placeholder="+00 0000 000000" value={contactNum} onChange={(e) => setContactNum(e.target.value)} className="blp-inp"/>
							{ showFormErrors && contactNum.length === 0 && <span className="err-blp">Contact number cannot be empty</span> }
							<p className="blp-label">Email</p>
							<input type="email" placeholder="john@gmail.com" value={email} onChange={(e) => setEmail(e.target.value)} className="blp-inp"/>
							{ showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && <span className="err-blp">Invalid email id</span> }				
						</>
				}
				{ !isButtonClicked && <div className="blp-sub-btn" onClick={() => setBtnClicked(true)}>Okay, sure!</div>}
				{ isButtonClicked && !isFormSubmitted && <div className="blp-sub-btn" onClick={() => handleSubmit(true)}>Submit</div>}
				{ isFormSubmitted && <a className="blp-sub-btn" href="https://akercarboncapture.com/">Back to homepage</a>}
			</div>
		</div>
		</>
	)
}

export default BlacklistPopup;