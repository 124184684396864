import Header from "../../Components/Header/Header";
import "./ErrorPage.css";

function ErrorPage() {
  document.title = "404 - Page not found";

  return (
    <>
      <Header hideCross />
      <div className="err-text-msg sec1-pr">
        <p>The page you are looking for can't be found.</p>
      </div>
    </>
  );
}

export default ErrorPage;
