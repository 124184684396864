import './RDButton.css';

function RDButton({ label, currentSector, sectorCallback, miniVersion }) {

	return (
		<button onClick={(e) => { e.preventDefault(); sectorCallback(label)}} className={`rdb-root ${ miniVersion ? 'mini-rdb' : ''} ${ currentSector === label ? 'active-rdb' : ''}`}>
			<div className={`rdb-ipc ${currentSector === label ? 'alt-ipc' : ''}`}>
				<div className={`${currentSector === label ? 'radio-act' : ''}`} />
			</div>
			<span className="rdb-label">{ label }</span>
		</button>
	)
}

export default RDButton;