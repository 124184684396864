import "./InfoDialog.css";

function InfoDialog({ className }) {
	return (
		<div className={`dialog-wrapper ${className}`}>
			<div className="arrow-left"/>
			<div className="id-root">
				<p>We estimate 8000 operating hours per year. If you have more or less than that, please use the yearly amount. The amount should indicate the CO2 quantity, and not the amount of flue gas. </p>
			</div>
		</div>
	)
}

export default InfoDialog;