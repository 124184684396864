import "./CircleAvatars.css";
import Miral from "../../Assets/Miral.png";
import Ida from "../../Assets/Ida.png";
import Anna from "../../Assets/Anna.png";

function CircleAvatars() {
	return (
		<div className="cia-root">
			<img src={Anna} alt="" className="cia-img"/>
			<img src={Ida} alt="" className="cia-img cia2" />
			<img src={Miral} alt="" className="cia-img cia3" />
		</div>
	)
}

export default CircleAvatars;