import "./BookMeeting.css";
import Cross from "../../Assets/close.svg";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import Call from "../../Assets/call.svg";
import Email from "../../Assets/email.svg";
import Clock from "../../Assets/clock.svg";
import { useState, useRef, useEffect } from "react";
import CircleAvatars from "../CircleAvatars/CircleAvatars";
import axios from "axios";
import _ from "lodash";

function BookMeeting({ isOpen, setOpen, companyName, recommId }) {
  const coreRef = useRef(null);
  useOutsideClickNotifier(coreRef, setOpen);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [isSubmitDisabled, toggleSubmitDisabled] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNum, setContactNum] = useState("");
  const [interest, setInterest] = useState("");
  const [preferredComm, setPreferredComm] = useState("Email");

  const [showFormErrors, setFormErrors] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [hasSubmitBeenPressed, setPressed] = useState(false);

  const checkFormValidity = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      interest.length === 0 ||
      !email.match(/^\S+@\S+\.\S+$/)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  };

  useEffect(() => {
    if (isFormValid) {
      toggleSubmitDisabled(true);
      var payload = {
        recommId: recommId,
        uid: localStorage.getItem("InteractionID"),
        firstName: firstName,
        lastName: lastName,
        email: email,
        companyName: companyName ? companyName : "aker",
        mobile: contactNum === "" ? null : contactNum,
        preferredComm: preferredComm === "Email" ? "email" : "call",
        discussionTopic: interest,
      };
      if (payload.contact === null) {
        _.unset(payload, "contact");
      }
      // console.log(payload);
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/book-meeting`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            setFormSubmitted(true);
            toggleSubmitDisabled(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toggleSubmitDisabled(false);
        });
    }
  }, [isFormValid]);

  const handleSubmit = () => {
    if (!hasSubmitBeenPressed) {
      setPressed(true);
      setFormErrors(true);
    }
    checkFormValidity();
    if (!isFormValid) {
      return;
    }
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "initial");
  }, []);

  return (
    <>
      <div className="bm-overlay" style={{ top: window.scrollY }}>
        <div
          ref={coreRef}
          className={`bm-popup-root ${isFormSubmitted ? "hd-sb-bm" : ""}`}
        >
          <p className={`bm-title ${isFormSubmitted ? "" : "ns-title"}`}>
            <img className="bm-cross hd-bm-cross" src={Cross} alt="" />
            {isFormSubmitted ? (
              <span className="smol-bm-title">
                Thank you for showing your interest in our offering
              </span>
            ) : (
              "Let's discuss"
            )}
            <img
              onClick={() => setOpen(!isOpen)}
              className={`bm-cross ${isFormSubmitted ? "alt-crs" : ""}`}
              src={Cross}
              alt=""
            />
          </p>
          <p className={`dialog-text ${isFormSubmitted ? "hd-bm-frm" : ""}`}>
            Our engineers are here for a dialog
            <br />
            around your cases
          </p>
          <img
            onClick={() => setOpen(!isOpen)}
            className={`bm-cross alt-cross-style ${
              isFormSubmitted ? "ns-title" : ""
            }`}
            src={Cross}
            alt=""
          />
          <div className={`cia-wrapper ${isFormSubmitted ? "hd-bm-frm" : ""}`}>
            <CircleAvatars />
            <div className="cia-ct">
              <p className="re-text">Our usual reply time</p>
              <p className="clo-text">
                <img src={Clock} alt="" className="clck-img" />
                Within a day
              </p>
            </div>
          </div>
          <form
            className={`bm-inps-wrapper ${isFormSubmitted ? "hd-bm-frm" : ""}`}
          >
            {/* For desktop and tablet */}
            <div className="bm-flex-wrapper">
              <div className="fb-40">
                <p className="bm-label">First name</p>
                <input
                  required
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="John"
                  className="bm-frm-input flx-input"
                />
                {showFormErrors && firstName.length === 0 && (
                  <span className="err-bm">First name cannot be empty</span>
                )}
              </div>
              <div className="fb-40 ml-max">
                <p className="bm-label">Last name</p>
                <input
                  required
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Doe"
                  className="bm-frm-input flx-input"
                />
                {showFormErrors && lastName.length === 0 && (
                  <span className="err-bm">Last name cannot be empty</span>
                )}
              </div>
            </div>
            <div className="bm-flex-wrapper">
              <div className="fb-40">
                <p className="bm-label">Email</p>
                <input
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="john@doe.com"
                  className="bm-frm-input flx-input"
                />
                {showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && (
                  <span className="err-bm">Invalid email id</span>
                )}
              </div>
              <div className="fb-40 ml-max">
                <p className="bm-label">Contact number</p>
                <input
                  type="number"
                  onWheelCapture={(e) => e.target.blur()}
                  onChange={(e) => setContactNum(e.target.value)}
                  placeholder="+00 0000 000000"
                  className="bm-frm-input flx-input"
                />
              </div>
            </div>
            {/* For mobile */}
            <p className="bm-label hd-deskt">First name</p>
            <input
              required
              type="text"
              placeholder="John"
              onChange={(e) => setFirstName(e.target.value)}
              className="bm-frm-input hd-deskt"
            />
            {showFormErrors && firstName.length === 0 && (
              <span className="err-bm-alt hd-deskt">
                First name cannot be empty
              </span>
            )}
            <p className="bm-label hd-deskt">Last name</p>
            <input
              required
              type="text"
              placeholder="Doe"
              onChange={(e) => setLastName(e.target.value)}
              className="bm-frm-input hd-deskt"
            />
            {showFormErrors && lastName.length === 0 && (
              <span className="err-bm-alt hd-deskt">
                Last name cannot be empty
              </span>
            )}
            <p className="bm-label hd-deskt">Email</p>
            <input
              required
              type="email"
              placeholder="john@mail.com"
              onChange={(e) => setEmail(e.target.value)}
              className="bm-frm-input hd-deskt"
            />
            {showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && (
              <span className="err-bm-alt hd-deskt">Invalid email id</span>
            )}
            <p className="bm-label hd-deskt">Contact number</p>
            <input
              type="number"
              placeholder="+00 0000 000000"
              onWheelCapture={(e) => e.target.blur()}
              onChange={(e) => setContactNum(e.target.value)}
              className="bm-frm-input hd-deskt"
            />
            <p className="bm-label">Topic for discussion</p>
            <input
              type="text"
              placeholder="Discussion topic"
              onChange={(e) => setInterest(e.target.value)}
              className="bm-frm-input"
            />
            {showFormErrors && interest.length === 0 && (
              <span className="err-bm-alt">Topic cannot be empty</span>
            )}
            <p className="bm-label">Preferred way of communication?</p>
            <div className="flx-comms-wrapper mb-68">
              <div
                onClick={() => setPreferredComm("Email")}
                className={`comms-btn ${
                  preferredComm === "Email" ? "comms-active" : ""
                }`}
              >
                <img src={Email} alt="" className="comm-img" />
                Email
              </div>
              <div
                onClick={() => setPreferredComm("Call back")}
                className={`comms-btn ${
                  preferredComm === "Call back" ? "comms-active" : ""
                }`}
              >
                <img src={Call} alt="" className="comm-img" />
                Call back
              </div>
            </div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="bm-frm-button"
              disabled={isSubmitDisabled}
            >
              {isSubmitDisabled ? "Submitting..." : "Let's speak"}
            </button>
          </form>
          {isFormSubmitted && (
            <p className="thank-text">
              Our executive will be reaching out to you catering to your
              requirements and taking the conversation forward.
              <br />
              <br />
              <br className="br-hide" />
              <br />
              <a
                href="https://akercarboncapture.com/"
                className="bm-frm-button mt-113"
              >
                Go to homepage
              </a>
              <br />
              <br />
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default BookMeeting;
