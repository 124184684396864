import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import "./ProgressTracker.css";

function ProgressTracker({ value }) {
	return (
		<>
			<div className='progress-circl'>
				<CircularProgressbar value={value} text={value + '/4'} strokeWidth={5} maxValue={4} styles={buildStyles({textColor: '#005C59', pathColor: '#005C59'})}/>
			</div>
			<div className='progress-barr'>Step {value} of 4</div>
			<div className='col-bar' style={{width: `${(value / 4) * 100}%`}} />
		</>
	)
}

export default ProgressTracker;