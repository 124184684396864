import { useState, useRef, useEffect } from "react";
import Cross from "../../Assets/close.svg";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import axios from "axios";
import "./ExitPopup.css";

function ExitPopup({ isOpen, setOpen, progressPayload }) {
  const coreRef = useRef(null);
  useOutsideClickNotifier(coreRef, setOpen);
  const [email, setEmail] = useState("");
  const apiPayload = {};

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isFormVisible, setFormVisible] = useState(false);
  const [showFormErrors, setFormErrors] = useState(false);
  const [isFormValid, setFormValid] = useState(false);
  const [hasSubmitBeenPressed, setPressed] = useState(false);
  const [isSubmitDisabled, toggleSubmitDisabled] = useState(false);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "initial");
  }, []);

  const getPayload = () => {
    apiPayload.email = email;
    apiPayload.firstName = firstName;
    apiPayload.lastName = lastName;
    apiPayload.progressData = progressPayload;
  };

  useEffect(() => {
    if (isFormValid) {
      toggleSubmitDisabled(true);
      getPayload();
      axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/api/form-progress`,
        data: apiPayload,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toggleSubmitDisabled(false);
            setOpen(false);
          }
        })
        .catch((e) => {
          console.log(e);
          toggleSubmitDisabled(false);
        });
    }
  }, [isFormValid]);

  const checkFormValidity = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      !email.match(/^\S+@\S+\.\S+$/)
    ) {
      setFormValid(false);
      return;
    }
    setFormValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hasSubmitBeenPressed) {
      setPressed(true);
      setFormErrors(true);
    }
    checkFormValidity();
    if (!isFormValid) {
      return;
    }
  };

  return (
    <>
      <div className="ep-overlay" />
      <div ref={coreRef} className="ep-popup-root">
        <p className="ep-title">
          <img className="ep-cross hd-ep-cross" src={Cross} alt="" />
          Are you sure <br />
          you want to exit?
          <img
            onClick={() => setOpen(!isOpen)}
            className="ep-cross"
            src={Cross}
            alt=""
          />
        </p>
        {isFormVisible ? (
          <form className="frm-recomm">
            <p className="ep-thank-text mb-24">
              We will send you a link on the email id below
            </p>
            <p className="spp-label">First Name</p>
            <input
              type="text"
              placeholder="John"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className="spp-inp"
            />
            {showFormErrors && firstName.length === 0 && (
              <span className="err-spp">First name cannot be empty</span>
            )}
            <p className="spp-label">Last Name</p>
            <input
              type="text"
              placeholder="Doe"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              className="spp-inp"
            />
            {showFormErrors && lastName.length === 0 && (
              <span className="err-spp">Last name cannot be empty</span>
            )}
            <p className="spp-label">Email</p>
            <input
              className="spp-inp"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {showFormErrors && !email.match(/^\S+@\S+\.\S+$/) && (
              <span className="err-spp mt-10-err">Invalid email id</span>
            )}
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              className="ep-frm-button"
              disabled={isSubmitDisabled}
            >
              Done
            </button>
          </form>
        ) : (
          <p className="ep-thank-text">
            We can always continue from where you left off, just share your
            email id and we will save all your information on a link sent to you
            on your email.
            <br />
            <button
              onClick={() => {
                setFormVisible(true);
              }}
              className="ep-frm-button"
            >
              Sure, send me the link
            </button>
            <br />
            <a className="ep-link" href="https://akercarboncapture.com/">
              Exit now
            </a>
          </p>
        )}
      </div>
    </>
  );
}

export default ExitPopup;
