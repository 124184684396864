const PlantInfo = {
	LOC: 'location',
	CRPY: 'co2ReleasedPerYear',
	CTBC: 'co2ToBeCaptured',
	UA: 'utilitiesAvailable',
	DMOC: 'downstreamManagementOfCO2',
	FD: 'flueDetails',
	IPSF: 'isPlantSpecFilled',
	COORD: 'co-ordinates'
};

Object.freeze(PlantInfo);

export { PlantInfo };