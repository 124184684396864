import "./CustomInput.css";
import Tick from "./../../Assets/tick.svg";

function CustomInput({ isClicked, style, label, onClick }) {
	return (
		<div onClick={() => onClick()} style={style}>
			<div className="ci-root">
				<div className={`ci-box ${isClicked ? 'ci-ticked' : ''}`}>
					{ <img src={Tick} alt="" />}
				</div>
				<span className="ci-label">{label}</span>
			</div>
		</div>
	)
}

export default CustomInput;