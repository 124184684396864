import "./FlueSpecs.css";
import Upload from "../../Assets/upload.svg";
import Close from "../../Assets/close.svg";
import { PlantInfo } from "../../Enums/PlantInfo";
import { useRef } from "react";

function FlueSpecs({
  plantName,
  plantAddress,
  showFileInvalidErr,
  id,
  flueDetails,
  flueLink,
  setFlueDetails,
  withByline,
  hasSubmitBeenPressed,
}) {
  const hfInput = useRef(null);

  //user think he clik on btn, but
  //he clik on inp instead huhuhu
  //sneak 100
  const handleClick = (e) => {
    e.preventDefault();
    hfInput.current.click();
  };

  return (
    <>
      <div className={`flue-info-inp ${withByline ? "mb-12" : ""}`}>
        <div>
          <p className="mb-4">{plantName}</p>
          <p className="flue-info-inp-subt">
            {plantAddress === "" ? "Plant Location" : plantAddress}
          </p>
          {/* For tablet and mobile */}
          <div
            className={`${
              flueDetails === "" ? "hd-upld-file" : ""
            } btm-wrapper`}
          >
            <div className={`mob-upld-file`}>
              <a
                href={flueLink}
                target="_blank"
                rel="noopener noreferrer"
                className="fl-nm-lim"
              >
                {flueDetails !== "" ? flueDetails : ""}
              </a>
              <img
                src={Close}
                alt=""
                className="del-file"
                onClick={() => setFlueDetails(id, PlantInfo.FD, "")}
              />
            </div>
            <div className="padder" />
            <button
              className="upload-flue-wrapper"
              onClick={(e) => handleClick(e)}
            >
              <span className="flue-upload-btn">
                <img src={Upload} alt="" className="flue-img-icon" />
                <span className="upload-flue">UPLOAD</span>
              </span>
            </button>
          </div>
        </div>
        <input
          type="file"
          ref={hfInput}
          accept=".pdf, .doc, .docx, .xls, .xlsx"
          onChange={(e) => {
            setFlueDetails(id, PlantInfo.FD, e.target.files[0]);
            e.target.value = null;
          }}
          style={{ display: "none" }}
        />
        <div
          className={`fle-wrapper ${
            flueDetails === "" ? "" : "hd-mb-upld-wrapper"
          }`}
        >
          <button
            className="upload-flue-wrapper"
            onClick={(e) => handleClick(e)}
          >
            <span className="flue-upload-btn">
              <img src={Upload} alt="" className="flue-img-icon" />
              <span className="upload-flue">UPLOAD</span>
            </span>
          </button>
          {/* For desktop */}
          <div
            className={`${flueDetails === "" ? "hd-upld-file" : ""} upld-file`}
          >
            <a
              href={flueLink}
              target="_blank"
              rel="noopener noreferrer"
              className="fl-nm-lim"
            >
              {flueDetails}
            </a>
            <img
              src={Close}
              alt=""
              className="del-file"
              onClick={() => setFlueDetails(id, PlantInfo.FD, "")}
            />
          </div>
        </div>
      </div>
      {(hasSubmitBeenPressed && flueDetails === "") || showFileInvalidErr ? (
        <p className="err-fs">Please upload a pdf, excel or word file.</p>
      ) : (
        <></>
      )}
      {withByline && (
        <p className="flue-info-inp-byline">
          It's always good to do a quick check on flue gas if that is available,
          <br className="hd-lbr" /> but don't worry - you can go back later and
          share those with us.
        </p>
      )}
    </>
  );
}

export default FlueSpecs;
