import { useRef, useState } from "react";
import DownArrow from "../../Assets/small-dwn-arrow.svg";
import { PlantInfo } from "../../Enums/PlantInfo";
import useOutsideClickNotifier from "../../Hooks/useOutsideClickNotifier";
import "./SingleDropdownSelect.css";

function SingleDropdownSelect({ index, currentValue, fixValue, options, isMobile }) {
	const [isOpen, toggleOpen] = useState(false);
	const sdsRef = useRef(null);
	useOutsideClickNotifier(sdsRef, toggleOpen);

	return (
		<div ref={sdsRef} onClick={() => toggleOpen(!isOpen)} className={`sds-root ${isMobile ? 'sds-hide' : ''} ${isOpen ? 'exp-sds' : ''}`}>
			<span className="sds-select">
				{currentValue === '' ? <span className="pcolor">Select</span> : currentValue}
				<img src={DownArrow} alt="" className="exp-icon"/>
			</span>
			<div>
				{
					options.map((item, i) => {
						return (
							<span className="exp-list-item" onClick={() => fixValue(index, PlantInfo.DMOC, item)} key={i}>
								<div className={`rd-green ${item === currentValue ? 'sds-selected' : '' }`}>
									<div className={`sds-inner-btn ${item === currentValue ? '' : 'hd-gtick'}`} />
								</div>
								{item}
							</span>
						)
					})
				}
			</div>
		</div>
	)
}

export default SingleDropdownSelect;