import "./CTAButton.css";

function CTAButton({ title, isDisabled, style, giveLeftMargin, onClick }) {
	return (
		<button disabled={isDisabled} className={`ctab-root ${isDisabled ? '' : 'alt-bg'} ${giveLeftMargin ? 'male-30' : ''}`} style={style} onClick={onClick}>
			{title}	
		</button>
	)
}

export default CTAButton;