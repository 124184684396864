import "./Toast.css";
import Up from "../../Assets/upwhite.svg";
import { useState } from "react";

function Toast({ text, animationDuration }) {
	const [isExpanded, setExpanded] = useState(false);

	return (
		<div style={{ animationDuration: animationDuration}} className={`toast-root ${isExpanded ? 'exp-toast' : ''}`}>
			<p className={`${isExpanded ? 'toast-dyk' : 'hd-txt-mb'}`}>Did you know?</p>
			<p className={`toast-content ${isExpanded ? 'lim-wid' : 'lim-text'}`}><span className={`${!isExpanded ? 'mob-collapse-text' : 'hd-txt-mb'}`}>Did you know? </span>{text}</p>
			<div className="squares-wrapper">
				<div className="sq-clck sq2" onClick={() => setExpanded(true)}>
					<img src={Up} alt="" className="up-ico-img" />
				</div>
				<div className="sq-clck sq1" onClick={() => setExpanded(false)}>
					<img src={Up} alt="" className="up-ico-img inv-up-ico" />
				</div>
			</div>
		</div>
	)
}

export default Toast;